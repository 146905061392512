import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { ParallaxBanner } from 'react-scroll-parallax';
import './index.scss';

class Section extends React.Component {

  render() {
    var bgChildren = '';
    var headerClass = classNames(
      'header',
      {
        [`h-${this.props.height ? this.props.height : `auto`}`]: true,
        [`header-${this.props.ui ? this.props.ui : `60`}`]: true,
        [`bg-${this.props.bgColor ? this.props.bgColor : `primary-800-pastel`}`]: true,
        'bg-image': this.props.bgImage,
        'bg-video': this.props.bgVideo,
        // `btn-pressed`: this.state.isPressed,
        // `btn-over`: !this.state.isPressed && this.state.isHovered
        }
    );
    if (this.props.bgVideo) {
      //bgChildren
    }
    else if (this.props.bgImage){
      var bgImage = {
        backgroundImage: `url(${this.props.bgImage})`
      }
      bgChildren = (
        <div className="bg-container" style={bgImage}/>
      )
    }
    if (this.props.parallax ) {
      return (
        <ParallaxBanner
          className={headerClass}
          layers={this.props.parallax}
        >
          <div className="content y-center">
            {this.props.children}
          </div>
        </ParallaxBanner>
      )
    } else {
      return (
        <header className={headerClass}>
          {bgChildren}
          <div className="content y-center">
            {this.props.children}
          </div>
          {this.props.bgSlider && this.props.bgSlider}
        </header>
      )
    }
  }
}

export default Section