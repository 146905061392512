import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Header from 'components/Headers/Header';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

class PageNotFound extends React.Component {
  render () {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allPrismicDocument.edges');
    const data = get(this, 'props.data');
    var headerProps = {
      height: `70vh`,
      bgColor: `gray-cold-100`,
      ui: 90,
    }
    return (
      <Layout>
        <Fluid>
          <Helmet title={`${`Unauthorized`}`} />
          <Header {... headerProps}>
            <Offset>
              <h1 className={`fs-60em lh-10 ls--10em mb-3em fw-300`}>Unauthorized</h1>
              <p className="lead fs-20em">Sorry, you are not allowed to access this page.</p>
              <p className={`pb-20em`}>Perhaps you would like to go to the <Link style={{ fontWeight: `600` }} to={`/`}>Apantac's homepage</Link>, <Link style={{ fontWeight: `600` }} to={`/search/`}>Search our website</Link> or <Link style={{ fontWeight: `600` }} to={`/company/contact/`}>Contact Us</Link>.</p>
            </Offset>
          </Header>
        </Fluid>
      </Layout>
    );
  }
}

export default PageNotFound;
